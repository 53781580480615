import React, { useState, useRef } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import fb from "../Firebase/firebase";
import emailjs from '@emailjs/browser';
const DB = fb.firestore()
const CF = DB.collection('Celesta-Forte_Form');

const Form = (props) => {

    const form = useRef();
    const [userData, setUserData] = useState({
        Name: "",
        Email: "",
        Mobile: "",
        Quantity: "",
        RetailerSelfUse: ""
    });

    let name, value;
    const postUserData = (e) => {
        name = e.target.name;
        value = e.target.value;
        setUserData({ ...userData, [name]: value });
    }

    //connect with firebase
    const SubmitData = async (e) => {
        e.preventDefault();
        const { Name, Email, Mobile, Quantity, RetailerSelfUse, Products } = userData;
        if (Name && Email && Mobile && Quantity && RetailerSelfUse && Products) {


            const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
            if (!emailRegex.test(userData.Email)) {
                toast.warning('Please enter a valid email address');
                return
            }

            if (typeof Mobile !== "undefined") {

                var pattern = new RegExp(/^[0-9\b]+$/);

                if (!pattern.test(userData.Mobile)) {
                    toast.warning("Please enter only number.");
                    return

                } else if (userData.Mobile.length !== 10) {
                    toast.warning("Please enter 10 digit Mobile number.");
                    return
                }
            }

            try {

                CF.add({
                    Name: Name,
                    Email: Email,
                    Mobile: Mobile,
                    Quantity: Quantity,
                    RetailerSelfUse: RetailerSelfUse,
                    Products: Products
                }).then((docRef) => {
                    toast.success('Order Received Successfully');
                }).catch((error) => {
                    console.error("error:", error);
                });


                emailjs.sendForm('service_91sqknx', 'template_4j6wwya', form.current, '6pbmyufjSURA60XeS')
                    .then((result) => {
                        console.log(result.text);

                    }, (error) => {
                        console.log(error.text);
                    });

                setUserData({
                    Name: "",
                    Email: "",
                    Mobile: "",
                    Quantity: "",
                    RetailerSelfUse: "",
                    Products: ""
                })

                // console.log("Document written with ID: ", docRef.id);
            } catch (e) {
                console.error("Error adding document: ", e);
                toast.warning("Error adding document")
            }
        }
        else {
            toast.warning('Please fill the data');
        }
    };

    return (
        <>
            <ToastContainer />
            <div className='form' style={{ display: props.show }}>
                <h3>Buy Now</h3>
                <svg onClick={props.close} id="Capa_1" enableBackground="new 0 0 320.591 320.591" height="512" viewBox="0 0 320.591 320.591" width="512" xmlns="http://www.w3.org/2000/svg"><g><g id="close_1_"><path d="m30.391 318.583c-7.86.457-15.59-2.156-21.56-7.288-11.774-11.844-11.774-30.973 0-42.817l257.812-257.813c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875l-259.331 259.331c-5.893 5.058-13.499 7.666-21.256 7.288z" /><path d="m287.9 318.583c-7.966-.034-15.601-3.196-21.257-8.806l-257.813-257.814c-10.908-12.738-9.425-31.908 3.313-42.817 11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414-6.35 5.522-14.707 8.161-23.078 7.288z" /></g></g></svg>
                <form action="POST" ref={form}>
                    <div className='input'>
                        <input type="text" placeholder='Name'
                            name='Name'
                            value={userData.Name}
                            onChange={postUserData}
                            required />
                        <input type="email" placeholder='Email'
                            style={{ textTransform: 'lowercase' }}
                            name='Email'
                            value={userData.Email}
                            onChange={postUserData}
                            required />
                    </div>
                    <div className='input'>
                        <input type="text" placeholder='Mobile'
                            name='Mobile'
                            value={userData.Mobile}
                            onChange={postUserData}
                            required />
                        <input type="number" min={1} placeholder='Quantity'
                            name='Quantity'
                            value={userData.Quantity}
                            onChange={postUserData}
                            required />
                    </div>
                    <input style={{ display: "none" }}
                        type="text"
                        name="productName"
                        value="Celesta-Forte"
                    />
                    <select name='RetailerSelfUse'
                        value={userData.RetailerSelfUse}
                        onChange={postUserData}>
                        <option hidden selected="selected" disabled="disabled">Retailer / Self Use</option>
                        <option value="Retailer">Retailer</option>
                        <option value="Self Use">Self Use</option>
                    </select>
                    <select name="Products"
                        value={userData.Products}
                        onChange={postUserData}>
                        <option hidden selected="selected" disabled="disabled">Select Product</option>
                        <option value="Celesta-Forte 250">Celesta-Forte 250</option>
                        <option value="Celesta-Forte 500">Celesta-Forte 500</option>
                    </select>
                    <button onClick={SubmitData}>Submit</button>
                </form>
            </div>
        </>

    )
}

export default Form
